.container {
    background: var(--white-default);
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    box-shadow: var(--shadow);
    min-width: 2rem;

    > :first-child {
        padding: .6rem .7rem;
    }

    svg {
        fill: var(--primary-default);
        margin-right: .5em;
        stroke: var(--grey-dark);
    }
}

.icon-update {
    margin-inline-end: 0;
}
