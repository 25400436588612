// table/spreadsheet elements
.table-row {
    background-color: #fff;
    border: var(--borderTrans);
    border-bottom: .1rem solid var(--colorBlueDark5);

    display: flex;
    flex-flow: row nowrap;
    height: 3.8rem;
    justify-content: flex-start;
    overflow: visible;
    position: relative;
    transition: var(--transition);

    p {
        font-size: 1.4rem;
        font-weight: 400;
    }

    &:hover {
        border: var(--borderBlue);
    }
}

.table-row-selected {
    background-color: var(--light-grey);
    border: var(--borderBlue);
    box-shadow: var(--shadowBoxY);

    .table-cell {
        background-color: var(--light-grey);
    }
}

.table-cell {
    align-items: center;
    background-color: #fff;

    display: flex;
    flex-shrink: 0;
    font-size: 1.4rem;
    font-weight: 400;
    overflow: hidden;
    padding: 0 1rem;
    text-align: left;
    text-overflow: ellipsis;
    transition: var(--transition);
    white-space: nowrap;
    word-wrap: break-word;
}

.last-spreadsheet-line {
    border-bottom-width: 0;
}
