.container {
    height: 2rem;
    width: 2rem;

    [class='CircularProgressbar-trail'] {
        stroke: var(--grey-default);
    }

    [class='CircularProgressbar-path'] {
        stroke: var(--primary-default);
    }
}
