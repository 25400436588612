.icon {
    margin: 0 auto;

    svg {
        fill: var(--grey-dark);
        height: 2rem;
        stroke: var(--grey-dark);
        width: 2rem;
    }
}
