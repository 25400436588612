.container {
    background: var(--success-lightest-2);
    border: 1px solid var(--success-lighter);
    border-radius: var(--border-radius-small);
    cursor: default;
    padding: 1.6rem;
    position: relative;
    width: 40.5rem;

    p {
        margin-top: .4rem;
        white-space: pre-wrap;
    }

    &.warning {
        background: var(--primary-lightest-2);
        border-color: var(--primary-lighter);
    }

    &.error {
        background: var(--error-lightest-2);
        border-color: var(--error-lighter);
    }
}

.icon {
    margin-right: 1.6rem;

    svg {
        height: 2.4rem;
        stroke: var(--primary-default);
        width: 2.4rem;
    }
}

.close {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
}

.content-wrapper {
    flex: 1;
}
