.pages {
    bottom: 0;
    left: 5rem;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 5rem;

    > div {
        height: 100%;
    }
}

.project-type-icon {
    vertical-align: middle;

    svg {
        fill: var(--dark-grey);
        height: 2rem;
    }
}

.is-open {
    display: block;
}

.is-closed {
    display: none;
}

.cards-container {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1.25rem;
    padding-block-end: 3rem;
    z-index: 1;
}

.card-nickname {
    font-size: 1.5rem;
    margin-bottom: .5rem;
}

.paragraph {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.bold {
    font-weight: 600;
}

.link-label {
    cursor: pointer;
    text-decoration-color: var(--accent-1-default);
    text-decoration-line: underline;
}

.is-disabled {
    color: var(--grey-light);
    cursor: not-allowed;
}

.spreadsheet-container {
    border-radius: .5rem;
    display: flex;
    flex-flow: column;
    width: max-content;

    > section {
        width: max-content;
    }
}

.spreadsheet-container-no-groups {
    @extend .spreadsheet-container;
    overflow: scroll;
    width: 100%;
}

.popup-list {
    backdrop-filter: var(--semi-blur);
    background-color: var(--semi-white);
    border: var(--borderLightGrey);
    border-radius: .3rem;
    box-shadow: var(--button-shadow);
    padding: 1.6rem 2rem;

    p {
        color: var(--black);
        font-size: 1.4rem;
        font-weight: 400;
        padding: .3rem 0;
    }
}

.spreadsheet-header-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.spreadsheet-header-cell {
    align-items: center;

    cursor: pointer;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 1rem;
    user-select: none;
    white-space: nowrap;

    p {
        color: var(--dark-grey);
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.spreadsheet-totals-menu-row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    user-select: none;
}

.spreadsheet-totals-menu-row-label {
    color: var(--dark-grey) !important;
    margin-inline-end: 1rem;
    white-space: nowrap;
}

.spreadsheet-totals-title {
    color: var(--dark-grey);
    display: grid;
    font-size: 1.2rem;
    font-weight: 500;
    height: 3.7rem;
    left: 3rem;
    padding-inline-start: 1rem;
    place-items: center;
    position: absolute;
    white-space: nowrap;
}

.spreadsheet-row {
    background-color: #fff;
    border: var(--borderTrans);
    border-bottom: .1rem dotted var(--light-grey);
    border-left: none; /* stylelint-disable-line */

    display: flex;
    flex-flow: row nowrap;
    height: 4.2rem;
    justify-content: flex-start;
    overflow: visible;

    &:hover {
        border: var(--borderBlue);
        border-left: none; /* stylelint-disable-line */
        transition: none;
    }

    &:hover .edit svg {
        display: block;
    }


    p {
        font-size: 1.4rem;
        font-weight: 400;
    }

    > #table_cell, #info_icon {
        background-color: #fff;
    }
}

.spreadsheet-header-row-regular {
    @extend .spreadsheet-header-row;
}

.spreadsheet-row-selected {
    background-color: var(--light-grey);
    border: var(--borderBlue);
    border-left: none; /* stylelint-disable-line */

    > #table_cell, #info_icon {
        background-color: var(--light-grey);
    }
}

.toast-underlay {
    animation: flipInX .75s ease-out, flipOutX .75s 6s ease-in forwards;
    backface-visibility: visible !important;
    background-color: #fff;

    margin: 1rem;
    max-width: 54rem;
    min-width: 30rem;
    position: relative;
}

.toast-indicator {
    animation: maxWidthTo0 6s ease-in forwards;
    bottom: 0;
    height: .3rem;
    max-width: 100%;
    position: absolute;
    width: 100%;
}

.white-background-80 {
    width: 80rem;
}

.button-isolator {
    padding: 2rem 0;
    width: 80rem;
}

.button-isolator-small {
    padding: 1rem 0;
    width: 100%;
}

.button-icon {
    transition: var(--transition);

    svg {
        cursor: pointer;
        height: 1.3rem;
        width: 1.2rem;
    }
}

.button-close {
    @extend .button-icon;

    svg {
        stroke: var(--dark-grey);
    }

    &:hover svg {
        stroke: var(--red);
    }
}

.button-edit {
    @extend .button-icon;

    svg {
        fill: var(--dark-grey);
    }

    &:hover svg {
        fill: var(--blue);
    }
}

// used by website renderer
.label-margin {
    margin-top: .9rem;
}

.separator {
    border-bottom: 1px solid var(--semi-grey);
    margin: 1rem;
    width: 100%;
}

.editable-delete-button {
    display: flex;
    height: 0;
    justify-content: flex-end;
    position: absolute;
    right: 2rem;
    top: 2rem;

    svg {
        cursor: pointer;
        height: 1.2rem;
        margin-bottom: 1rem;
        stroke: var(--dark-grey);
        width: 1.2rem;
    }

    &:hover svg {
        stroke: var(--blue);
    }
}

.react-calendar__tile--active {  /* stylelint-disable-line */
    color: var(--blue);
    font-weight: 500;
}

.input {
    border: var(--borderGrey);
    border-radius: .3rem;
    font-size: 1.5rem;
    padding: .7rem;
    transition: var(--transition);
    width: 100%;

    &:active,
    &:focus {
        border: var(--borderBlue);
        color: var(--blue);
    }

    &::placeholder {
        color: var(--light-grey);
        font-weight: 300;
    }
}

.input-small {
    font-size: 1.6rem;
    margin-block-start: -.6rem;
    padding: .5rem .8rem;
    text-align: end;
}

.list-element-border {
    border-bottom: var(--borderLightGrey);
    margin-block-end: 1rem;
    padding-block-end: 1rem;
}

// picture next/prev
.picture-arrow {
    background-color: var(--light-black);
    cursor: pointer;
    display:grid;
    height: 100%;
    opacity: 0;
    padding: 0 .8rem;
    place-items:center;
    position: absolute;
    transition:var(--transition);

    svg {
        height: 1.5rem;
        stroke: #fff;
        width: 1.5rem;
    }

    &:hover {
        background-color:var(--semi-black)
    }
}

.picture-arrow-left {
    @extend .picture-arrow;

    left: 0;
}

.picture-arrow-right {
    @extend .picture-arrow;

    right: 0;
}

.picture-arrow-parent {
    &:hover {
        .picture-arrow-left,
        .picture-arrow-right {
            opacity: 1;
        }}
}

.picture-details-modal {
    height: 77%;
    overflow: auto;
    width: 77%;
}

.modal-60pct {
    padding: 1rem;
    width: 60%;
}

.modal-40pct {
    overflow: visible;
    width: 40%;
}

.modal-90by50 {
    height: 50rem;
    padding: 1rem;
    width: 90rem;
}

.comments-modal {
    height: fit-content;
    padding: 2rem;
    width: fit-content;
}

.modal-assign-company {
    max-width: 95%;
}

.modal-downloads {
    height: 80%;
    padding: 0;
    width: 80%;
}

.color-grey {
    color: var(--grey);
}

.background-white {
    background-color: #fff;
}

.confirmation-content {
    color: var(--dark-grey);
    font-size: 1.5rem;
    font-weight: 300;
    // margin-bottom: 1.5rem;
    margin-inline-start: 4rem;
    margin-top: .5rem;
    transition: var(--transition);

    a {
        color: var(--dark-grey);

        &:hover {
            color: var(--blue);
        }
    }

    #checkbox {
        margin-bottom:0;
        margin-right: 0;
    }
}

.logo {
    animation-delay: 1s;
    display: grid;
    left: 0;
    place-items: center;
    position: absolute;
    width: 16rem;

    svg {
        fill: var(--blue);
        height: 2.4rem;
    }
}

.navbar-button {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 1.5rem 2rem;
    position: relative;

    &:disabled {
        cursor: not-allowed;
    }

    svg {
        fill: var(--dark-grey);
        height: 2rem;
        width: 2rem;
    }

    &:enabled:hover svg {
        fill: var(--blue);
    }
}

/* pop-up style */
.popup-content {
    border-bottom-width: 0 !important;
    border-left-width: 0 !important;
    border-radius: .3rem;
    border-right-width: 0 !important;
    border-top-width: 0 !important;
    font-size: 1.6rem;
    z-index: 1000 !important;
}

.billing-tab {
    height: 2rem;
    margin-inline-end: .5rem;
    width: 1.6rem;
}
