.container {
    background: var(--white-default);
    border: .1rem solid var(--grey-lighter);
    border-radius: var(--border-radius-small);
    flex: 0 0 auto;
    height: 2.4rem;
    position: relative;
    width: 27.1rem;
    z-index: 3;
}

.input-wrap {
    flex: 1;
    position: relative;

    input {
        border: 0;
        color: var(--accent-1-default);
        font: 400 1.2rem var(--font);
        height: 100%;
        line-height: 1.6rem;
        padding: .4rem .8rem;
        text-align: left;
        width: 100%;
    }
}

.switch-wrap {
    border-left: .1rem solid var(--grey-lighter);
}

.close {
    position: absolute;
    right: .3rem;
    top: .3rem;
}
