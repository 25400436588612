.container {
    border: var(--border-grey-light);
    border-radius: var(--border-radius);
    padding: .2rem .8rem;

    &.focus {
        border-color: var(--primary-light);
    }

    input {
        flex: 1;
        font: 500 1.4rem var(--font);
        height: 3.4rem;
        line-height: 2rem;
        min-width: 40%;
    }

    input::placeholder {
        color: var(--black-light);
        user-select: none;
    }

    input:focus {
        caret-color: var(--primary-light)
    }

    &:global(.with-error) {
        border-color: var(--error-light);
    }
}

.disabled {
    background-color: var(--black-lightest-1);
    color: var(--black-light);
    cursor: not-allowed;

    input {
        cursor: not-allowed;
    }
}

.tags {
    max-width: 60%;

    > * {
        margin: .4rem .8rem .4rem 0;
        max-width: 100%;
    }
}
