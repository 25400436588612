.container {
    background-color: var(--primary-lighter);
    border-radius: 50%;
    color: var(--white-default);
    display: grid;
    height: 2.4rem;
    overflow: hidden;
    place-content: center;
    width: 2.4rem;
}

.disabled {
    background-color: var(--grey-light)
}

.image {
    max-height: 100%;
    max-width: 100%;
}

.image-disabled {
    filter: grayscale(100%);
}

.icon svg {
    fill: var(--white-default);
    height: 1.6rem;
    stroke: var(--white-default);
    width: 1.2rem;
}
