.container {
    position: relative;
}

.input {
    padding: .8rem 2.8rem;
    text-align: center;

    &[readonly] {
        cursor: pointer;
    }

    &:focus {
        border-color: var(--primary-light);
        caret-color: var(--primary-light)
    }

    &:disabled {
        background-color: var(--black-lightest-1);
        color: var(--black-light);
        cursor: not-allowed;
    }

    &::placeholder {
        color: var(--black-light);
        user-select:none;
    }
}

.icon {
    bottom: 0;
    margin: auto;
    position: absolute;
    top: 0;

    svg {
        fill: var(--grey-dark);
        height: 2rem;
        stroke: var(--grey-dark);
        width: 2rem;
    }

    &:disabled svg {
        fill: var(--grey-light);
        stroke: var(--grey-light);
    }
}

.icon:first-child {
    left: .8rem
}

.icon:last-child {
    right: .8rem
}
