.container {
    background-color: var(--white-default);
    flex-shrink: 0;
    height: 21.2rem;
    padding: 2.4rem;
    width: 31.6rem;
}

.address {
    color: var(--black-light);
    height: 4rem;
    margin-block-start: .4rem;
    overflow: hidden;
}
