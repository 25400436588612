.container {
    background: var(--white-light);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: var(--zi-confirmation);
}

.confirmation {
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    justify-content: flex-start;
    overflow: hidden;
    width: 36.5rem;

    >div {
        position: relative;
    }

    p {
        margin-bottom: 2.4rem;
        overflow: visible;
        white-space: pre-wrap;
    }
}
