.container {
    svg {
        animation: glimmer 1.5s 0s ease-in-out infinite;
        fill: var(--primary-default);
        height: 100%;
        opacity: 0;
        width: 100%;
    }

    @keyframes glimmer {
        0% {
            opacity: .2;
        }

        20% {
            opacity: 1;
        }

        100% {
            opacity: .2;
        }
    }
}

.full {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    svg {
        height: 4rem;
        width: 4rem;
    }
}

.small {
    svg {
        height: 3rem;
        width: 3rem;
    }
}

.tiny {
    svg {
        height: 1.5rem;
        width: 1.5rem;
    }
}
