.container {
    width: 100%;

    p {
        display: block;
    }
}

.progress {
    background: var(--primary-lightest-2);
    border-radius: .2rem;
    height: .4rem;
    overflow: hidden;
    width: 100%;

    > div {
        background: var(--primary-default);
        border-radius: .2rem;
        height: 100%;
    }
}
