.icon {
    margin: 0 auto;

    svg {
        fill: var(--grey-dark);
        height: 1.6rem;
        stroke: var(--grey-dark);
        width: 1.6rem;
    }

    &:disabled svg {
        fill: var(--grey-light);
        stroke: var(--grey-light);
    }
}
