.container {
    position: relative;

    input {
        width: 100%;
    }

    &:global(.with-error) input {
        border-color: var(--error-light);
    }

    .icon {
        position: absolute;
        top: 1.2rem;

        &:first-child {
            left: 1.2rem;
        }

        &:last-child {
            right: 1.2rem;
        }

        svg {
            fill: var(--grey-dark);
            height: 1.6rem;
            stroke: var(--grey-dark);
            width: 1.6rem;
        }
    }

    .loading {
        position: absolute;
        right: 1.2rem;
        top: 1rem;
        z-index: 1;
    }
}

.with-loading input {
    padding-right: 4rem;
}

.with-left-icon input {
    padding-left: 3.6rem;
}

.with-right-icon input {
    padding-right: 3.6rem;
}

.with-right-icon.with-loading input {
    padding-right: 6.6rem;
}

.without-icon.with-loading input {
    padding-right: 6.6rem;
}

.with-right-icon .loading {
    right: 3.6rem;
}

.without-icon .loading {
    right: 3.6rem;
}

// Small size
.small {
    .icon {
        top: .8rem;

        &:first-child {
            left: 1.6rem;
        }

        &:last-child {
            right: .8rem;
        }
    }

    .loading {
        right: .8rem;
        top: .6rem;
    }
}

.small.with-loading input {
    padding-right: 3.4rem;
}

.small.with-left-icon input {
    padding-left: 4rem;
}

.small.with-right-icon input {
    padding-right: 3rem;
}

.small.without-icon.with-loading input {
    padding-right: 6rem;
}

.small.with-right-icon.with-loading input {
    padding-right: 6rem;
}

.small.with-right-icon .loading {
    right: 3.2rem;
}

.small.without-icon .loading {
    right: 3.2rem;
}

// xSmall size
.xsmall {
    .icon {
        top: .4rem;

        &:first-child {
            left: .8rem;
        }

        &:last-child {
            right: .8rem;
        }
    }

    .loading {
        right: .8rem;
        top: .5rem;

        div > span {
            height: 1.4rem;
        }
    }
}

.xsmall.with-loading input {
    padding-right: 3.2rem;
}

.xsmall.with-left-icon input {
    padding-left: 3rem;
}

.xsmall.with-right-icon input {
    padding-right: 3rem;
}

.xsmall.with-right-icon.with-loading input {
    padding-right: 5.4rem;
}

.xsmall.without-icon.with-loading input {
    padding-right: 5.4rem;
}

.xsmall.with-right-icon .loading {
    right: 3rem;
}

.xsmall.without-icon .loading {
    right: 3rem;
}
