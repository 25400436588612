.edit-button {
    background-color: #fff;
    border: .1rem solid #fff;
    display: grid;
    height: 100%;
    left: 0;
    margin-inline-start: -.1rem;
    place-items: center;
    position: sticky;
    width: 4rem;
    z-index: 101;

    svg {
        display: none;
        fill: var(--dark-grey);
        height: 1.2rem;
        width: 1.2rem;
    }

    &:hover svg {
        display: flex;
    }
}

.edit-button-selected {
    background-color: var(--light-grey);
    border-color: var(--light-grey);
    border-left-color: var(--blue);

    svg {
        display: flex;
    }
}

.edit-button-disabled {
    cursor: default;

    &:hover svg {
        display: none;
    }
}

textarea {
    font-family: var(--fontFamily);
}
