.button svg {
    fill: var(--grey-dark);
    height: 1.6rem;
    stroke: var(--grey-dark);
    width: 1.6rem;
}

.dropdown {
    min-width: 13.6rem;
}
