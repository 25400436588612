.container {
    background-color: var(--primary-default);

    &:hover {
        background: var(--primary-light);
    }

    &:active {
        background: var(--primary-dark);
    }

    &[disabled] {
        background: var(--grey-lighter);

        svg {
            fill: var(--grey-dark);
            stroke: var(--grey-dark);
        }

        .label {
            color: var(--grey-dark);
        }
    }

    svg {
        fill: var(--white-default);
        stroke: var(--white-default);
    }
}

.label {
    color: var(--white-default);
}
