.container {
    cursor: pointer;
    min-height: 4rem;
    padding: 0;

    > p {
        height: 100%;
        padding: 1rem 1.2rem;
        text-align: left;
        width: 100%;
    }

    &.small {
        min-height: 3.2rem;

        > p {
            padding: .8rem 1.6rem;
        }
    }

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.active > p {
        background-color: var(--accent-1-lightest-2);
    }

    &:hover:not(.disabled) > p,
    &.selected > p {
        background-color: var(--grey-lightest-2);
    }

    &.disabled {
        cursor: not-allowed;

        p {
            color: var(--grey-light);
        }
    }

    em {
        font-weight: 600;
        margin-inline-end: .5ch;
        transition: none;

        &::after {
            content:' |'}
    }
}

.split-after {
    border-bottom: .1rem solid var(--black-lighter);
}

.highlight-text {
    color: var(--primary-default);
}

.icon-wrapper,
.icon-wrapper svg {
    height: 1.6rem;
    width: 1.6rem;
}

.icon-wrapper {
    flex: 0 0 auto;
    margin-right: 1.6rem;

    svg {
        fill: var(--accent-1-default);
        stroke: var(--accent-1-default);
    }
}
