.container input {
    background-color: transparent;
    border-color: var(--grey-dark);

    &::placeholder {
        color: var(--grey-dark);
    }
}

.button svg {
    fill: var(--grey-dark);
    height: 2rem;
    stroke: var(--grey-dark);
    width: 2rem;
}
