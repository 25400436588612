.hover-blue-border {
    border-bottom: var(--borderTrans);

    &:hover {
        border-bottom: var(--borderBlue);
    }
}

.divider {
    border-bottom: var(--borderGrey);
    height: .2rem;
    margin-bottom: 2rem;
    margin-top: 1.9rem;
    width: 100%
}

.pointer {
    cursor: pointer;
}
