.container {
    flex: 1 0 auto;
    padding: 1.2rem 1.6rem;
}

.read-status {
    background: var(--accent-2-lighter);
    border-radius: 50%;
    flex: 0 0 auto;
    height: .8rem;
    margin: .6rem 1.6rem .6rem .6rem;
    width: .8rem;
}

.unread {
    background: var(--primary-lightest-1);
    cursor: pointer;

    .read-status {
        background: var(--error-default);
    }
}

.date {
    color: var(--black-light);
}

.download-button {
    margin-left: .4rem;
}
