.container {
    background: var(--accent-1-default);
    border-bottom: .2rem solid var(--white-lightest-2);
    border-radius: 0;
    color: var(--white-light);
    height: 100%;
    justify-content: center;
    overflow: hidden;

    > :first-child {
        padding: 1.6rem 1.5rem 1.4rem;
    }

    .label {
        color: var(--white-lightest-2);
        font-weight: 400;
    }

    .icon-update svg {
        height: 1.6rem;
        width: 1.6rem;
    }

    svg {
        fill: var(--white-lightest-2);
        stroke: var(--white-lightest-2);
    }

    &:active,
    &.selected,
    &:hover:not(.selected):not(:disabled) {
        border-bottom-color: var(--white-default);

        .label {
            color: var(--white-default);
        }

        svg {
            fill: var(--white-default);
            stroke: var(--white-default);
        }
    }

    &.selected {
        cursor: default;
    }

    &:hover:not(:active):not(.selected):not(:disabled) {
        border-bottom-color: var(--white-lighter);
    }

    &:disabled {
        cursor: default;

        * {
            display: none;
        }
    }
}
