.container {
    background: var(--white-default);
    border-radius: var(--border-radius);
    cursor: default;
    left: 0;
    margin: auto;
    overflow: hidden;
    padding: 1.6rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}
