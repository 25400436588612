.container {
    background: var(--white-default);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: var(--shadow);
    margin-top: .5rem;
    min-width: 2rem;
    top: 0;
    transform: translate3d(50%, 0, 0);

    svg {
        fill: var(--grey-dark);
        stroke: var(--grey-dark);
    }
}

.icon-update {
    margin-inline-end: 0;
}
