.container {
    position: relative;

    >* {
        width: 100%;
    }
}

.dropdown {
    background-color: var(--white-default);
    border: .1rem solid var(--black-transparent-lightest-1);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-3);
    margin-bottom: .4rem;
    margin-top: .4rem;
    overflow: auto;
    position: fixed;
    transition: none;
    z-index: var(--zi-dropdown);

    &:empty {
        display: none;
    }

    .loading {
        padding: 1rem;
    }

    &.inline {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        margin: 0;
        position: relative;
    }
}

.right {
    margin-left: .4rem;
}

.left {
    margin-right: .4rem;
}

.wrapper {
    position: relative;
}
