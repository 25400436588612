.input {
    background: var(--white-default);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
}

.menu {
    margin-top: .4rem;
}

.border-none {
    border: 0;
}

.button-open {
    .button-label {
        color: var(--primary-default);
    }

    svg {
        fill: var(--primary-default);
        stroke: var(--primary-default);
    }
}

.button-label {
    max-width: 22rem;
    transition: none;
}
