.container {
    cursor: pointer;
    padding: 2.4rem;
    text-decoration: none;

    h6 {
        white-space: nowrap;
    }

    &:hover,
    &:active,
    &.selected {
        background-color: var(--primary-dark);
    }

    &:visited {
        color: inherit;
    }
}

.label {
    color: #fff;
    margin: 0 auto;
}
