.container {
    border-radius: 0;
    flex: 0 0 auto;

    > :first-child {
        padding-bottom: 1.6rem;
        padding-left: 0;
        padding-top: 1.6rem;
        width: fit-content;
    }

    svg {
        fill: var(--primary-default);
        stroke: var(--primary-default);
    }

    &[disabled] {
        svg {
            fill: var(--grey-dark);
            stroke: var(--grey-dark);
        }

        .label {
            color: var(--grey-dark);
        }
    }
}

.label {
    color: var(--primary-default);
}

.secondary {
    background: var(--grey-default);

    > :first-child {
        padding: 1rem 1rem 1rem 1.6rem;
    }

    .label {
        color: var(--accent-3-default);
    }

    svg {
        fill: var(--accent-3-default);
        stroke: var(--accent-3-default);
    }
}
