.container {
    background-color: #fff;
    border-radius: var(--border-radius);
    bottom: .8rem;
    box-shadow: 0 1.6rem 2.4rem #00000019;
    overflow: hidden;
    position: fixed;
    right: .8rem;
    width: 37rem;
    z-index: 1000;
}

.sidebar-offset {
    right: 40.5rem;
}

.header {
    background-color: var(--accent-1-default);
    color: #fff;
    padding: 2.4rem;
}

.title {
    text-transform: none;
}

.close {
    svg {
        stroke: #fff;
    }

    &:hover svg {
        stroke: var(--grey-default);
    }
}

.sub-title-row {
    height: 3.2rem;
    padding: .9rem 2.4rem;
}

.cancel-button {
    color: var(--primary-default);
    cursor: pointer;
    font: 400 1rem/1.4rem var(--font);
    padding: .3rem .5rem;
}

.main-progress-bar {
    > div {
        border-radius: 0;

        > div {
            border-radius: 0 .2rem .2rem 0;
        }
    }
}

.uploads {
    max-height: 36rem;
    overflow: auto;
    position: relative;

    li:nth-child(odd) {
        background-color: var(--grey-lightest-2);
    }
}
