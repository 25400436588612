.p1 {
    font: 600 1.4rem var(--font);
    line-height: 2rem
}

.p2 {
    font: 400 1.4rem var(--font);
    line-height: 2rem
}

.p3 {
    font: 600 1.2rem var(--font);
    line-height: 1.6rem
}

.p4 {
    font: 400 1.2rem var(--font);
    line-height: 1.6rem
}

.p5 {
    font: 600 1rem var(--font);
    line-height: 1.4rem
}

.p6 {
    font: 400 1rem var(--font);
    line-height: 1.4rem
}
