.menu-container {
    animation: slideDown .5s 0s ease-in-out forwards;
    background-color: #fff;
    border: var(--borderLightGrey);
    border-radius: 0 0 .5rem .5rem;
    box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, .07);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 300px;
    overflow: auto;
    position: absolute;
    right: 1rem;
    top: 5rem;
    z-index: 1;

    .menu-section:not(:last-child) {
        border-bottom: var(--borderLightGrey);
    }
}


.menu-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: 2rem;
    min-width: 17rem;
    padding: 1rem 2rem;
    transition: var(--transition);
    user-select: none;
    white-space: nowrap;

    h5 {
        text-align: left;
    }

    &:hover {
        background-color: var(--blue);

        h5 {
            color: #fff;

        }
    }

    &:active {
        box-shadow: inset .2rem .5rem .5rem 0 rgba(0, 0, 0, .1);
    }

    &:disabled {
        background-color: #fff;
        box-shadow: none;
        cursor: not-allowed;

        h5 {
            color: var(--grey);
        }
    }
}
