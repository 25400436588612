.container {
    * {
        cursor: pointer;
        transition: var(--transition-slower);
    }

    label {
        color: var(--black-light);
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem;
        margin-left: 1.1rem;
        white-space: nowrap;
    }

    input {
        appearance: none;
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;

        &:checked + .slider {
            background-color: var(--primary-default);
        }

        &:checked + .slider::after {
            transform: translate3d(1rem, 0, 0);
        }
    }
}

.double input + .slider {
    background-color: var(--primary-default);
}

.slider {
    background-color: var(--black-lightest-2);
    border-radius: .7rem;
    cursor: pointer;
    height: 1.4rem;
    position: relative;
    width: 2.4rem;

    &::after {
        background: #fff;
        border-radius: .5rem;
        content: '';
        height: 1rem;
        left: .2rem;
        position: absolute;
        top: .2rem;
        transform: translate3d(0, 0, 0);
        transition: var(--transition-slower);
        width: 1rem;
    }
}

.disabled,
.disabled * {
    cursor: not-allowed;
}
