.container {
    background: var(--error-default);
    border-radius: 4rem;
    color: var(--white-default);
    font: 400 1rem/1.4rem var(--font);
    font-variant-numeric: proportional-nums;
    left: calc(100% - .4rem);
    padding: 0 .5rem;
    position: absolute;
    top: -.9rem;
}
