.dialog {
    background-color: #fff;
    border-radius: var(--border-radius);
    padding: 0;
    transition: none;
    z-index: var(--zi-resizable-dialog);
}

.dialog.active {
    z-index: var(--zi-resizable-dialog-active);
}
