@keyframes maxWidthTo0 {
    0% {
        max-width: 100%
    }

    100% {
        max-width: 0;
    }
}

@keyframes flipOutX {
    0% {
        transform: perspective(400px);
    }

    30% {
        opacity: 1;

        transform: perspective(400px) rotateX(-20deg);
    }

    to {
        opacity: 0;

        transform: perspective(400px) rotateX(90deg);
    }
}

@keyframes flipInX {
    0% {
        animation-timing-function: ease-in;
        opacity: 0;

        transform: perspective(400px) rotateX(90deg);
    }

    40% {
        animation-timing-function: ease-in;

        transform: perspective(400px) rotateX(-20deg);
    }

    60% {
        opacity: 1;

        transform: perspective(400px) rotateX(10deg);
    }

    80% {
        transform: perspective(400px) rotateX(-5deg);
    }

    to {
        transform: perspective(400px);
    }
}


@keyframes onOpen {
    0% {
        max-width: 0;
    }

    100% {
        max-width: 100%;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

