.container {
    svg {
        fill: var(--grey-dark);
        stroke: var(--grey-dark);
    }

    &:hover {
        .label {
            color: var(--grey-default);
        }

        svg {
            fill: var(--grey-default);
            stroke: var(--grey-default);
        }
    }

    &:active {
        .label {
            color: var(--grey-dark);
        }

        svg {
            fill: var(--grey-dark);
            stroke: var(--grey-dark);
        }
    }

    &[disabled] {
        .label {
            color: var(--grey-light);
        }

        svg {
            fill: var(--grey-light);
            stroke: var(--grey-light);
        }
    }
}

.label {
    color: var(--grey-darker);
}
