.container {
    padding: .3rem .8rem;

    .switch {
        margin: 0 .4rem;
    }

    svg {
        height: 1.6rem;
        stroke: var(--grey-dark);
        width: 1.6rem;
    }

    .active svg {
        stroke: var(--accent-1-default);
    }
}

.string-icon {
    color: var(--black-lighter);
    font-weight: 900;
}

.left {
    padding-right: .25rem;
}

.right {
    padding-left: .25rem;
}

.active-string-icon {
    color: var(--accent-1-default);
}
