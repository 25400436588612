.wrapper {
    position: fixed;
    z-index: var(--zi-navbar);
}

.container {
    background-color: var(--accent-3-default);
    height: 7.2rem;
    padding: 0 2.2rem;
    position: relative;
    z-index: 2;
}

.icon {
    cursor: pointer;
    display: grid;
    padding: .8rem;
    place-items: center;

    svg {
        height: 2.4rem;
        stroke: var(--grey-dark);
        width: 2.4rem;
    }

    &:active svg {
        stroke: var(--white-default);
    }
}

.beta-text {
    color: var(--warning-default);
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 4rem;
    margin-right: 3rem;
}
