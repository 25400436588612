.container {
    >div {
        height: auto !important;

        >div {
            height: auto !important;
            padding-inline-end: 2rem;
        }
    }
}
