.page-divider {
    background-color: var(--grey);
    height: .1rem;
    margin-block-end: 1rem;
    width: 100%;
}

.loading-container {
    bottom: 1rem;
    display: flex;
    height: 5rem;
    left: 6rem;
    overflow: visible;
    padding: 1rem;
    position: fixed;
    z-index: 100;
}

.page-with-tables {
    bottom: 0;
    display: flex;
    flex-flow: column;
    left: 0;
    overflow: hidden;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 0;
}

.page-with-tables-content {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: scroll;
    width: 100%;
}

.page-container {
    bottom: 0;
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.page-no-sidebar {
    flex-flow: column;
    // min-height: fit-content;
    padding: 2rem;
}

.page-content-with-sidebar {
    display: flex;
    height: auto;
    padding: 2rem;
    position: relative;
    width: 100%;
}

.page-header {
    align-items: flex-start;
    display: flex;
    flex-flow: column;
    height: fit-content;
    justify-content: flex-start;
    margin-block-end: 1rem;
    width: 100%;
    z-index: 300;
}

.page-breadcrumbs-row {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-block-end: 1rem;
    width: 100%;
}

.page-breadcrumbs-row {
    height: 3rem;
}

.page-subheader {
    align-items: flex-start;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: 5rem;
    justify-content: space-between;
    width: 100%;
}

.page-subheader-central {
    align-items: center;
    display: flex;
    flex-flow: column;
    height: 5rem;
    justify-content: flex-start;
    overflow-x: scroll;
    padding: 0 1rem;
    position: relative;
    width: 100%;
}

.content {
    bottom: 0;
    left: 2rem;
    overflow: scroll;
    position: absolute;
    right: 2rem;
    top: 6rem;

}

.content-after-full-header {
    @extend .content;
    top: 15.4rem;
}

.content-after-full-header-11 {
    @extend .content;
    top: 11rem;
}

.content-after-full-header-9 {
    @extend .content;
    top: 9rem;
}


.content-with-margins-for-cards {
    margin-inline-end: -1rem;
    margin-inline-start: -1rem;
}

.page-section {
    height: auto;
    margin-bottom: 5em;
    transition: var(--transition);
    width: 100%;
}

.page-section-content {
    margin: 0 -1rem;
}

.page-section-open {
    display: block;
}

.page-section-closed {
    display: none;
}

.page-section-row {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
}

.page-foldable-container-show {
    margin-inline-start: 0;
}
