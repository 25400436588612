.dropdown {
    border-radius: 0;
    margin-right: -2.2rem;
    margin-top: 1.6rem;
    max-height: 66.2rem;

    > *:not(:last-child) {
        border-bottom: var(--border-grey-lighter);
    }
}

.header {
    background: var(--white-default);
    left: 0;
    padding: 1.5rem 1.6rem;
    position: sticky;
    top: 0;
    z-index: 1;
}

.no-data {
    align-self: center;
    padding: 1.2rem 1.6rem;
}

.icon {
    cursor: pointer;
    display: grid;
    padding: .8rem;
    place-items: center;

    svg {
        height: 2.4rem;
        stroke: var(--grey-dark);
        width: 2.4rem;
    }

    &:active svg {
        stroke: var(--white-default);
    }
}

.counter {
    left: calc(100% - 1.5rem);
    top: -.2rem;
}
