.loading {
    .image {
        display: none;
    }

    .placeholder {
        display: block;
    }
}

.image,
.placeholder {
    height: 15rem;
    object-fit: cover;
    width: 15rem;
}

.placeholder {
    display: none;
}
