.container {
    border-bottom: var(--border-grey-lighter);
    padding: 1.9rem 2.4rem;
}

.title {
    max-width: 26rem;
}

.status {
    height: 2rem;
    margin-inline-end: 1rem;
    width: 2rem;

    svg {
        height: 2rem;
        width: 2rem;
    }
}
