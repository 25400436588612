.icon {
    background-color: var(--accent-1-lightest-2);
    border-radius: 50%;
    cursor: pointer;
    height: 2.5rem;
    margin: 0 1.4rem;
    width: 2.5rem;

    svg {
        fill: var(--accent-1-default);
    }
}

.container {
    align-self: center;
}
