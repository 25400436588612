.container {
    height: 14rem;
    margin: 0 2.4rem;
    width: 20rem;
}

.label {
    padding: 1.2rem .8rem;
    text-align: center;
    width: 100%;
}

.sub {
    background: linear-gradient(0deg, var(--black-transparent-lighter), var(--black-transparent-lighter)), var(--grey-default);
    border-radius: 50%;
    height: 3.2rem;
    position: absolute;
    right: -.4rem;
    top: -.4rem;
    width: 3.2rem;

    svg {
        fill: var(--white-default);
        height: 2rem;
        width: 2rem;
    }
}
