.accent-1,
.accent-1-transparent {
    background: var(--accent-1-lightest-1);
    border-color: var(--accent-1-light);
    color: var(--accent-1-default);

    .accent-1-close svg {
        stroke: var(--accent-1-default);
    }
}

.accent-1-transparent {
    background: transparent;
}

.accent-2 {
    background: var(--accent-2-lightest-1);
    border-color: var(--accent-2-light);
    color: var(--accent-2-default);

    .accent-2-close svg {
        stroke: var(--accent-2-default);
    }
}

.accent-3 {
    background: var(--accent-3-lightest-1);
    border-color: var(--accent-3-light);
    color: var(--accent-3-default);

    .accent-3-close svg {
        stroke: var(--accent-3-default);
    }
}

.error {
    background: var(--error-lightest-2);
    border: .1rem solid var(--error-light);
    color: var(--error-default);

    .error-close svg {
        stroke: var(--error-default);
    }
}
