.item {
    padding: .8rem 1.2rem;

    p:last-child {
        color: var(--black-lighter);
    }

    &:hover,
    &.selected {
        background: var(--grey-lightest-2);
    }
}
