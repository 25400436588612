.container {
    border-bottom: .2rem solid transparent;
    border-radius: 0;
    overflow: hidden;

    > :first-child {
        padding: 1.6rem 2.4rem 1.4rem;
    }

    .icon-update svg {
        height: 1.6rem;
        width: 1.6rem;
    }

    svg {
        fill: var(--accent-3-light);
        stroke: var(--accent-3-light);
    }

    &:active,
    &.selected {
        .label {
            color: var(--accent-3-darker);
        }

        svg {
            fill: var(--accent-3-darker);
            stroke: var(--accent-3-darker);
        }
    }

    &.selected {
        border-bottom-color: var(--primary-default);

        &:not(:global(.c-pointer)) {
            cursor: default;
        }
    }

    &:hover:not(.active):not(:disabled) {
        background: var(--primary-lightest-1);
    }
}

.label {
    color: var(--accent-3-light);
    overflow: hidden;
    text-overflow: ellipsis;
}

.container.small {
    > :first-child {
        padding: 1rem 2.4rem;
    }

    .label {
        font: var(--text-body-heavy);
        line-height: 2rem;
    }
}
