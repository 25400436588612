.container {
    background: var(--white);
    border-radius: var(--border-radius);
    position: relative;
    text-align: center;

    > :first-child {
        padding: 1.2rem 1.6rem;
    }

    &.folder {
        border: .1rem solid transparent;
        border-bottom-color: var(--black-lightest-2);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        min-width: 20rem;
        transition: none;
    }

    .label {
        color: var(--grey-dark);
        flex: 1;
    }

    .icon-update svg {
        height: 1.6rem;
        width: 1.6rem;
    }

    svg {
        fill: var(--grey-dark);
        stroke: var(--grey-dark);
    }

    &.selected {
        background: var(--accent-3-lightest-1);
        border: .1rem solid var(--black-lightest-2);
        border-bottom-color: transparent;
        cursor: default;
        z-index: 1;

        .label {
            color: var(--accent-2-darker);
        }

        svg {
            fill: var(--white-default);
            stroke: var(--white-default);
        }
    }

    &:hover:not(.selected) {
        .label {
            color: var(--grey-default);
        }

        svg {
            fill: var(--grey-default);
            stroke: var(--grey-default);
        }
    }

    &:active:not(.selected) {
        .label {
            color: var(--grey-dark);
        }

        svg {
            fill: var(--grey-dark);
            stroke: var(--grey-dark);
        }
    }

    &[disabled]:not(.selected) {
        cursor: default;

        .label {
            color: var(--grey-light);
        }

        svg {
            fill: var(--grey-light);
            stroke: var(--grey-light);
        }
    }
}

.counter {
    position: inherit;
}
