.toolbar {
    display: flex;
}

.button-action {
    &:hover:not(:disabled) {
        [class*='Dropdown_icon-left'] {
            svg {
                path:nth-of-type(1),
                path:nth-of-type(3),
                path:nth-of-type(4) {
                    stroke: var(--blue);
                }
            }
        }
    }
}

.button-generate {
    [class*='Dropdown_icon-left'] {
        svg {
            height: 1.5rem;
            margin-top: -.25rem;
            width: 1.5rem;
        }
    }
}

// TODO: use blank buttons
.button-no-background {
    background: none;
    color: var(--dark-grey);
    font-weight: 600;
}

.button-dropdown {
    color: var(--dark-grey);
    display:flex;
    font-weight: 600;
    justify-content: space-between;
    width: 100%;
}
