.container {
    padding: 1.6rem 3.2rem 1.6rem 2.4rem;

    button {
        background: transparent;
        border: .1rem solid var(--white-default);
        border-radius: var(--border-radius);
        color: var(--white-default);
        font: 600 1.4rem var(--font);
        height: 4rem;
        line-height: 2rem;
        padding: 1rem 1.6rem;
        text-align: left;
        width: 18.8rem;
    }

    &.disabled button {
        background: var(--white-lightest-1);
        border: 0;
        color: var(--white-lighter);
        cursor: default;
    }
}
