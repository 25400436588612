.container {
    background: var(--white-default);
    border: .1rem solid var(--grey-lighter);
    border-radius: var(--border-radius-small);
    height: 2.4rem;
    position: relative;
    z-index: 1;

    input {
        border: 0;
        border-radius: var(--border-radius-small);
        color: var(--accent-1-default);
        flex: 1;
        font: var(--text-caption-normal);
        height: 100%;
        line-height: 1.6rem;
        padding: .4rem .8rem;
        text-align: left;
        width: 100%;

        &[readonly] {
            background-color: transparent;
            border-color: transparent;
            color: var(--black-default);
            cursor: default;
            font: var(--text-body-normal);
            overflow: hidden;
            padding: 0;
            text-overflow: ellipsis;
        }
    }

    &:global(.disabled) {
        border-color: transparent;
    }
}

.with-loading input {
    padding-right: 2.8rem;
}

.close svg,
.submit svg {
    height: 1.6rem;
    width: 1.6rem;
}

.submit svg {
    stroke: var(--primary-default);
}

.submit {
    margin-right: .8rem;
}

.close {
    margin-right: .4rem;
}

.loading {
    background: var(--white-default);
    position: absolute;
    right: 5rem;
    top: .5rem;
    z-index: 1;

    div > span {
        height: 1.4rem;
    }
}
