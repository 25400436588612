.details {
    background-color: var(--colorBlueDark5);
    border-radius: var(--border-radius-small);
    font-family: monospace;
    font-size: 1.4rem;

    line-height: 2rem;
    padding: 1rem 2rem;

    summary {
        font-weight: 500;
    }
}

.message {
    color: var(--blue);
    font-family: monospace;
    font-size: 2rem;

}

.error-name {
    align-items: flex-start;
    border-left: .3rem solid var(--red);
    display: flex;
    justify-content: flex-start;
    margin-block-end: 3rem;
    margin-block-start: 3rem;
    padding-inline-start: .5rem;


    h3 {
        color: var(--red);
        font-family: monospace;
        font-size: 1.8rem;

        margin-inline-end: .5rem;
    }

    p {
        color: var(--red);
        font-family: monospace;
        font-size: 1.7rem;

        line-height: 2rem;

    }
}

