.flex {
    display: flex;
}

.center {
    display: grid;
    place-items: center;
}

.column {
    display: flex;
    flex-direction: column;
}

.d-none {
    display: none;
}

.o-hidden {
    overflow: hidden;
}

.o-auto {
    overflow: auto;
}

.flex-1 {
    flex: 1;
}

.row-centered {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.text-nowrap {
    white-space: nowrap;
}

.w-max {
    width: max-content;
}

.w-auto {
    width: auto;
}

.w-inherit {
    width: inherit;
}


.h-auto {
    height: auto;
}

.p2 {
    padding: 2rem;
}

.py-1 {
    padding: 1rem 0;
}

.py-end-1 {
    padding-block-end: 1rem;
}

.px-start-1 {
    padding-inline-start: 1rem;
}

.mx-start-1 {
    margin-inline-start: 1rem;
}

.m0 {
    margin: 0 !important;
    margin-block-end: 0 !important;
    margin-block-start: 0 !important;
    margin-inline-end: 0 !important;
    margin-inline-start: 0 !important;
}

.m1 {
    margin: 1rem;
}

.my2-auto {
    margin: 2rem auto;
}

.p1 {
    padding: 1rem;
}

.p2 {
    padding: 2rem;
}

.transparent {
    background-color: #0000;
}

.position-absolute {
    position: absolute;
}

.circle {
    border-radius: 50%;
}

.relative {
    position: relative;
}

.hidden {
    display: none;
}

.underlined {
    text-decoration: underline;
}

.bolder {
    font-weight: 600 !important;
}

.text-end {
    text-align: end;
}

.text-center {
    text-align: center;
}

.text-start {
    text-align: start;
}

.full-page {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
}


.spacer-vertical-2r {
    flex: 0 0 2rem;
    height: 2rem;
    width: 100%;
}

.spacer-vertical-3r {
    height: 3rem;
    width: 100%;
}

.mxe-1ch {
    margin-inline-end: 1ch;
}
