.h1 {
    font: 700 3.6rem var(--font);
    line-height: 4.8rem
}

.h2 {
    font: 400 3.6rem var(--font);
    line-height: 4.8rem
}

.h3 {
    font: 700 2.4rem var(--font);
    line-height: 3.2rem
}

.h4 {
    font: 400 2.4rem var(--font);
    line-height: 3.2rem
}

.h5 {
    font: 600 2rem var(--font);
    line-height: 2.8rem
}

.h6 {
    font: 400 2rem var(--font);
    line-height: 2.8rem
}

.h7 {
    font: 600 1.6rem var(--font);
    line-height: 2.4rem
}

.h8 {
    font: 400 1.6rem var(--font);
    line-height: 2.4rem
}
