.container {
    position: relative;

    * {
        transition-property: stroke, fill;
    }
}

.button {
    cursor: pointer;
    padding: 0;
}

.button:disabled {
    cursor: not-allowed;
}
