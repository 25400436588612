.container {
    width: 2rem;

    span {
        background-color: var(--accent-2-lighter);
        height: 2rem;
        width: .3rem;
    }

    span:nth-of-type(1) {
        animation: grow 1s -.45s ease-in-out infinite;
    }

    span:nth-of-type(2) {
        animation: grow 1s -.3s ease-in-out infinite;
    }

    span:nth-of-type(3) {
        animation: grow 1s -.15s ease-in-out infinite;
    }

    span:nth-of-type(4) {
        animation: grow 1s ease-in-out infinite;
    }
}


@keyframes grow {
    0%,
    100% {
        transform: scaleY(.5);
    }

    50% {
        transform: scaleY(1);
    }
}
