.container {
    background: var(--white-default);
    border: var(--border-grey-light);
    border-radius: var(--border-radius);
    font: 500 1.4rem var(--font);
    height: 4rem;
    line-height: 2rem;
    padding: .9rem .8rem;

    &[readonly] {
        cursor: pointer;
    }

    &:focus {
        border-color: var(--primary-light);
        caret-color: var(--primary-light)
    }

    &:disabled {
        background-color: var(--black-lightest-1);
        color: var(--black-light);
        cursor: not-allowed;
    }

    &::placeholder {
        color: var(--black-light);
        user-select:none;
    }

    &:global(.with-error) {
        border-color: var(--error-light) !important;
    }
}

.small {
    font: 400 1.4rem var(--font);
    height: 3.2rem;
}

.xsmall {
    border-radius: var(--border-radius-small);
    color: var(--accent-1-default);
    font: 400 1.2rem var(--font);
    height: 2.4rem;
}
