.icon {
    svg {
        fill: var(--grey-darker);
        height: 2rem;
        stroke: var(--grey-darker);
        width: 2rem;
    }

    &.small svg {
        fill: var(--grey-dark);
        height: 1.6rem;
        stroke: var(--grey-dark);
        width: 1.6rem;
    }

    &:disabled svg {
        fill: var(--grey-light);
        stroke: var(--grey-light);
    }
}
