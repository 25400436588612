.section {
    height: 20rem;
    overflow: hidden;
}

.projects-line > div {
    display: flex;
    width: 100%;

    >div {
        margin-block-start: 3rem
    }
}
