.container {
    border-radius: 5rem;
    cursor: pointer;
    padding: 0;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    width: fit-content;

    .inner-container {
        min-height: 3.2rem;
    }

    &[disabled] {
        cursor: not-allowed;
    }

    &:visited {
        color: inherit;
    }

    svg {
        transition: none;
    }
}

.large {
    .inner-container {
        min-height: 4rem;
        padding: .9rem 1.6rem;

        &.icon-only {
            padding: 1rem;
        }
    }

    .label {
        font: var(--text-body-heavy);
        line-height: 2rem;
    }

    .icon svg {
        height: 2rem;
        width: 2rem;
    }
}

.small {
    .inner-container {
        padding: .7rem 1.6rem;

        &.icon-only {
            padding: .8rem;
        }
    }

    .label {
        font: var(--text-caption-heavy);
        line-height: 1.6rem;
    }

    .icon svg {
        height: 1.6rem;
        width: 1.6rem;
    }
}

.xsmall {
    .inner-container {
        min-height: 2.4rem;
        padding: .4rem .8rem;

        &.icon-only {
            padding: .5rem;
        }
    }

    .label {
        font: 600 1rem var(--font);
        line-height: 1.4rem;
    }

    .icon svg {
        height: 1.2rem;
        width: 1.2rem;
    }
}

.icon svg {
    fill: var(--black-default);
    flex: 1;
    stroke: var(--black-default);
}

.icon-right {
    @extend .icon;
    margin-inline-start: .8rem;
}

.icon-left {
    @extend .icon;
    margin-inline-end: .8rem;
}

.icon-only .icon-left,
.icon-only .icon-right {
    margin-inline: 0;
}

.large-with-right .inner-container {
    padding: .9rem 2rem .9rem 3rem;
}

.large-with-left .inner-container {
    padding: .9rem 3rem .9rem 2rem;
}

.small-with-right .inner-container {
    padding: .7rem 1.6rem .7rem 2.4rem;
}

.small-with-left .inner-container {
    padding: .7rem 2.4rem .7rem 1.6rem;
}

.small-with-both .inner-container {
    padding: .7rem 1.6rem;
}

.xsmall-with-right .inner-container {
    padding: .4rem 1rem .4rem 1.5rem;
}

.xsmall-with-left .inner-container {
    padding: .4rem 1.5rem .4rem 1rem;
}

.xsmall-with-both .inner-container {
    padding: .4rem 1rem;
}

.icon-only.icon-right, .icon-only.icon-left {
    margin-inline-end: 0;
    margin-inline-start: 0;
}

.icon-only.large-with-right .inner-container {
    padding: .9rem 1.2rem;
}

.icon-only.small-with-right .inner-container {
    padding: .7rem ;
}

.waiting-animation {
    visibility: hidden;
}

.peek-out {
    animation-delay: 0, 2s;
    animation-duration: .7s, 3s;
    animation-name: peekOut, wave;
    animation-timing-function: ease-in-out, ease-out;
    visibility: visible;
}

@keyframes peekOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100);
    }
}

@keyframes wave {
    0% {
        background-color: var(--white-default);
    }

    40% {
        background-color: var(--accent-1-lightest-1)
    }

    60% {
        background-color: var(--accent-1-lightest-2);
    }

    80% {
        background-color: var(--accent-1-lightest-1);
    }

    100% {
        background-color: var(--white-default);
    }
}
