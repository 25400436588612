.container {
    border: var(--border-grey-default);

    &:hover:not([disabled]) {
        background: var(--grey-lightest-2);
    }

    &:active:not([disabled]) {
        background: var(--grey-lighter);
    }

    &[disabled] {
        border: var(--border-grey-lighter);

        .label {
            color: var(--grey-default);
        }

        svg {
            fill: var(--grey-default);
            stroke: var(--grey-default);
        }
    }

    svg {
        fill: var(--grey-dark) ;
        stroke: var(--grey-dark) ;
    }
}

.label {
    color: var(--grey-darker);
}
