.container {
    border-radius: 0;
    height: fit-content;
    margin: auto 0;

    > :first-child {
        padding: 1rem !important;
    }

    .icon-update {
        margin: 0;

        svg {
            fill: #fff;
            height: 36px;
            stroke: none;
            transition: var(--transition-faster);
            width: 180px;
        }
    }

    &:active svg {
        fill: var(--white-lighter);
    }

}

