.w-700 {
    font-weight: 700;
}

.w-500 {
    font-weight: 500;
}

.w-400 {
    font-weight: 400;
}

.w-300 {
    font-weight: 300;
}

.fs-14 {
    font-size: 1.4rem;
}
