.container {
    background: var(--white-light);
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: pre-wrap;
    z-index: 10;
}

.transparent {
    background: transparent;
}

.icon svg {
    fill: var(--accent-3-default);
}
