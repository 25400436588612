.container {
    height: 18.8rem;

    > div {
        display: flex;
        width: 100%;

        > div {
            margin-block-start: 2.4rem
        }
    }
}
