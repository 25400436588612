.container {
    background: var(--primary-lightest-1);
    border: .1rem solid var(--primary-light);
    border-radius: var(--border-radius-small);
    color: var(--primary-default);
    flex: 0 0 auto;
    font: 600 1.2rem var(--font);
    line-height: 1.6rem;
    padding: .4rem .8rem;

    .close {
        margin-left: .8rem;

        svg {
            height: 1.6rem;
            stroke: var(--primary-default);
            width: 1.6rem;
        }
    }
}

.small {
    font-size: 1rem;
    line-height: 1.4rem;
    padding: 0 .6rem;

    .close {
        margin-left: .4rem;

        svg {
            height: 1.2rem;
            width: 1.2rem;
        }
    }
}
