.container {
    svg {
        fill: var(--white-default);
        stroke: var(--white-default);
    }

    &[disabled] {
        svg {
            fill: var(--white-light);
            stroke: var(--white-light);
        }

        .label {
            color: var(--white-light);
        }
    }
}

.label {
    color: var(--white-default);
}
